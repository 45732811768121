import { css, cx } from '@linaria/core';
import React from 'react';
import { SkeletonBlock } from 'src/components/Skeleton';
import { FlexColumn, FlexRow } from 'src/composing/Flex';
import { SpacerV } from 'src/composing/Spacing';
import { useDev } from 'src/scopes/dev/DevContext';
import { devTransparent } from 'src/theme/AtomicClasses';
import { Colors } from 'src/theme/constants';
import { useTheme } from 'src/theme/ThemeContext';
export function LinkPreviewSkeleton() {
  const {
    skeletonShow
  } = useDev();
  const {
    isMobile
  } = useTheme();
  return <FlexRow flexDirection={isMobile ? 'column-reverse' : undefined} className={cx(containerClass, isMobile && mobileContainerClass, skeletonShow && devTransparent)}>
      <FlexColumn className={detailClass}>
        {!isMobile && <>
            <SkeletonBlock h={1.1875} w={'90%'} />
            <SpacerV rem={0.125} />
            <SkeletonBlock h={1.1875} w={'50%'} />
            <SpacerV rem={0.125} />
            <SkeletonBlock h={1.1875} w={'100%'} />
          </>}
        <SpacerV rem={0.125} />
        <SkeletonBlock h={1.1875} w={'90%'} />
        <SpacerV rem={0.125} />
        <SkeletonBlock h={1.1875} w={'100%'} />
        <SpacerV rem={0.5} />
        <SkeletonBlock h={1.1875} w={'40%'} />
      </FlexColumn>
      <SkeletonBlock h={isMobile ? 18.5 : 10.25} w={isMobile ? '100%' : 10.25} borderRadius={0} />
    </FlexRow>;
}
const containerClass = "c17yrtal";
const mobileContainerClass = "m1kq29w2";
const detailClass = "dxdgktj";

require("../../../.linaria-cache/packages/oxcart/src/scopes/posts/LinkPreview.skeleton.linaria.module.css");