import {
  FeedResourceType,
  Link,
  MemoFeedItem,
  MemoResource,
  PostFeedItem,
  TradeFeedItem,
  TradeResource
} from '@commonstock/common/src/api/feed'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { MentionAttachments } from '@commonstock/common/src/types/mentions'

type DestructedMemo = MemoFeedItem & {
  memo?: MemoResource
  user?: HydratedUserProfile
  mentionAttachments: MentionAttachments
  linkedTrades: TradeResource[]
}
export function destructMemoFeedItem(memoFeedItem: MemoFeedItem): DestructedMemo {
  const { resources } = memoFeedItem
  let memo: MemoResource | undefined = resources.memos[memoFeedItem.uuid]
  let user: HydratedUserProfile | undefined = resources.users[memoFeedItem.user_uuid]

  // BE return a wrong object when it fails to grab it from DB, so we need to check for the current uuid of them
  memo = memoFeedItem.uuid === memo?.uuid ? memo : undefined
  user = memoFeedItem.user_uuid === user?.uuid ? user : undefined

  const asset_mentions = memo?.asset_mentions.map(id => resources.assets[id] || null).filter(Boolean)
  const user_mentions = memo?.user_mentions.map(id => resources.users[id] || null).filter(Boolean)
  const mentionAttachments: MentionAttachments = { asset_mentions, user_mentions }

  const linkedTrades: TradeResource[] =
    memo?.related_trades?.map(id => resources.trades[id] || null).filter(Boolean) || []

  return { ...memoFeedItem, memo, user, mentionAttachments, linkedTrades }
}

export function feedItemToTrade(memoFeedItem: MemoFeedItem | PostFeedItem, trade: TradeResource): TradeFeedItem | null {
  if (!memoFeedItem.resources.trades[trade.uuid]) return null
  return {
    ...memoFeedItem,
    created_at: trade.created_at,
    resource_type: FeedResourceType.TRADE,
    uuid: trade.uuid,
    user_uuid: trade.user_uuid
  }
}

export function getMemoPreviewLink(memo: MemoResource): Link | undefined {
  const linksList = Object.values(memo.links)
  const linkWithImage = linksList.find(link => !!link.image)
  const firstLink = linksList.length ? linksList[0] : undefined
  return linkWithImage || firstLink
}
