import React, { useCallback, useState } from 'react';
import { useEphemeralModal } from '../modal/Modal';
import { MemoFeedItem } from '@commonstock/common/src/api/feed';
import { SecondaryButton } from '../../composing/CSButton';
import dynamic from 'next/dynamic';
import { useGetDraftMemos } from '@commonstock/common/src/api/memo';
import AnimatedEllipsis from 'src/components/AnimatedEllipsis';
import { css } from '@linaria/core';
import { unexpectedError } from 'src/dev/sentry';
const DraftMemoModal = dynamic(() => import('./DraftMemoModal'));
type Props = {
  currentDraft: MemoFeedItem | null;
  setCurrentDraft: (memo: MemoFeedItem | null) => void;
  handleSave: (publish: boolean, updateDraft?: boolean | undefined) => Promise<void>;
  disabled: boolean;
  empty: boolean;
  setError: (error: string) => void;
  confirmDelete: (draft: MemoFeedItem | null) => void;
};
export function DraftMemoButtons({
  setCurrentDraft,
  currentDraft,
  handleSave,
  disabled,
  empty,
  setError,
  confirmDelete
}: Props & {
  isPost?: boolean;
}) {
  const [pending, setPending] = useState(false);
  const [drafts] = useGetDraftMemos();
  const onSaveDraft = useCallback(async (updateCurrent?: boolean) => {
    setPending(true);
    setError('');

    try {
      await handleSave(false, !!updateCurrent);
    } catch (error) {
      setError('Error while saving draft');
      unexpectedError(error);
    }

    setPending(false);
  }, [handleSave, setError]);
  const modal = useEphemeralModal();
  const goToDrafts = useCallback(() => {
    if (!drafts) return;
    modal(<DraftMemoModal drafts={drafts} setCurrentDraft={setCurrentDraft} currentDraft={currentDraft} unsavedContent={!disabled} saveCurrentDraft={onSaveDraft} confirmDelete={confirmDelete} />);
  }, [drafts, modal, setCurrentDraft, currentDraft, disabled, onSaveDraft, confirmDelete]);
  return <>
      {empty ? null : <SecondaryButton small onClick={() => onSaveDraft(true)} disabled={disabled} className={saveDraftButton}>
          {pending ? <AnimatedEllipsis>Saving</AnimatedEllipsis> : currentDraft && disabled ? 'Saved' : 'Save Draft'}
        </SecondaryButton>}
      {drafts?.length ? <SecondaryButton small onClick={goToDrafts}>
          Drafts ({drafts.length})
        </SecondaryButton> : null}
    </>;
}
const saveDraftButton = "s1axehcy";

require("../../../.linaria-cache/packages/oxcart/src/scopes/memo-drafts/DraftMemoButtons.linaria.module.css");